// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-edit-js": () => import("./../../src/pages/edit.js" /* webpackChunkName: "component---src-pages-edit-js" */),
  "component---src-pages-requests-js": () => import("./../../src/pages/requests.js" /* webpackChunkName: "component---src-pages-requests-js" */),
  "component---src-templates-blog-post-js": () => import("./../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-comparisons-js": () => import("./../../src/templates/comparisons.js" /* webpackChunkName: "component---src-templates-comparisons-js" */),
  "component---src-templates-index-with-search-js": () => import("./../../src/templates/index-with-search.js" /* webpackChunkName: "component---src-templates-index-with-search-js" */)
}

